/* Стили для кнопки на тулбаре */
.ql-toolbar {
    position: relative;
}

.ql-emoji {
    font-size: 16px;
    line-height: 1;
    padding: 0 !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
}

/* Стили для выпадающего списка эмодзи */
.emoji-dropdown {
    position: absolute;
    bottom: 40px;
    left: 10px;
    background: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 1000;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30px, 1fr));
    gap: 5px;
    max-width: 200px;
    max-height: 150px;
    overflow-y: auto;
}

/* Стили для кнопок эмодзи */
.emoji-dropdown button {
    width: 30px;
    height: 30px;
    font-size: 20px;
    cursor: pointer;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}