.project {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    width: 40%;
    max-height: 90%;
    background-color: #ffffff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    z-index: 1001;
    overflow: auto;
}

.project__title {
    margin: 8px 0 15px;
    /* font-size: 1.8rem; */
    color: #333;
    text-align: center;
    width: 100%;
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 10px;
}

.project__description {
    margin: 8px 0;
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    text-align: left;
    width: 100%;
}

.project__description span {
    font-weight: bold;
    color: #333;
}

.project__close {
    position: absolute;
    top: 0;
    right: 8px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #555;
    transition: color 0.2s ease-in-out;
}

.project__close:hover {
    color: #ff4d4d;
}

.project__list {
    list-style: none;
    padding: 0;
    margin: 15px 0;
    width: 100%;
}

.project__list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #f0f0f0;
    font-size: 1rem;
    color: #333;
}

.project__list-item button {
    background-color: #ff4d4d;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 5px 10px;
    cursor: pointer;
    /* font-size: 0.9rem; */
    transition: background-color 0.2s ease-in-out;
}

.project__list-item button:hover {
    background-color: #ff3333;
}

.project__heading {
    margin: 20px 0 10px;
    /* font-size: 1.3rem; */
    color: #333;
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 5px;
}

.project__select {
    width: 100%;
    margin-bottom: 15px;
    padding: 8px;
    /* font-size: 1rem; */
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    outline: none;
    transition: border-color 0.2s ease-in-out;
}

.project__select:focus {
    border-color: #007bff;
}

.project__button {
    background-color: hsl(211.06deg 100% 50%);
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 10px 15px;
    cursor: pointer;
    /* font-size: 1rem; */
    transition: background-color 0.2s ease-in-out;
    width: 100%;
}

.project__button:hover {
    background-color: hsl(211.06deg 100% 57%);
}

.project__button:active {
    background-color: hsl(211.06deg 100% 40%);
}


/* Большие экраны */
@media (min-width: 1025px) {}

/* Ноутбуки */
@media (max-width: 1024px) {}

/* Планшеты */
@media (max-width: 768px) {
    .project {
        width: 50%
    }

    .project__list-item, .dropdown, .project__select {
        font-size: 0.9em;
    }
}

@media (max-width: 600px) {
    .project {
        width: 60%
    }
    .project__list-item, .dropdown, .project__select {
        font-size: 0.85em;
    }
}

/* Мобильные устройства */
@media (max-width: 480px) {
    .project {
        width: 70%
    }

    .project__list-item, .dropdown, .project__select {
        font-size: 0.8em;
    }
}