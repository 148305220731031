/* Стили для TabList */
.react-tabs__tab-list {
    display: flex;
    justify-content: center;
    padding: 0;
    list-style: none;
    margin: 0 30px 0 30px;
    flex-wrap: wrap; /* Добавлено для адаптивности */
}

/* Стили для каждого Tab */
.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    background: none;
    color: #183b59;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
    flex: 1 1 auto; /* Добавлено для адаптивности */
    text-align: center; /* Добавлено для адаптивности */
}

.react-tabs__tab a {
    text-decoration: none;
}

.react-tabs__tab--selected {
    background-color: #fff;
    border-color: #aaa;
    border-radius: 5px 5px 0 0;
    color: #55a8f0;
}

.react-tabs__tab:focus {
    border-color: #0188fe;
    box-shadow: 0 0 5px #0188fe;
    outline: none;
    animation: fadeBlue 2s forwards; /* Анимация исчезновения синего цвета */
}

@keyframes fadeBlue {
    0% {
        border-color: #0188fe; 
        box-shadow: 0 0 5px #0188fe;
    }
    100% {
        border-color: #aaa;
        box-shadow: 0 0 5px transparent;
    }
}

.react-tabs__tab--selected::after {
    display: none !important;
  }

/* Стили для TabPanel */
.react-tabs__tab-panel {
    padding: 20px;
    border: 1px solid #aaa;
    border-top: none;
    background-color: rgba(255, 255, 255, 0.7);
    margin-left: 30px;
    margin-right: 30px;
}

.react-tabs__tab-panel--selected {
    display: block;
}

.react-tabs__tab-panel--hidden {
    display: none;
}

/* Адаптивные стили */
@media (max-width: 768px) {
    .react-tabs__tab-list {
        margin: 0 10px; /* Уменьшаем отступы */
    }

    .react-tabs__tab {
        padding: 10px 5px; /* Уменьшаем внутренние отступы */
        font-size: 14px; /* Уменьшаем размер шрифта */
    }

    .react-tabs__tab-panel {
        margin-left: 10px; /* Уменьшаем отступы */
        margin-right: 10px; /* Уменьшаем отступы */
    }
}
