/* Модальное окно личного профиля */
.user-profile__own {
    position: absolute;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    z-index: 1000;
    max-width: 300px;
    margin-right: 20px;
    margin-top: 10px;
}

/* Модальное окно чужого профиля */
.user-profile__modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    max-width: 300px;
    width: 70%;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    color: unset;
}

/* Затемненный фон */
.user-profile__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

/* Карточка профиля */
.user-profile__card {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Имя пользователя */
.user-profile__name {
    font-size: 1.4rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-top: 10px;
}

/* Блоки информации */
.user-profile__login,
.user-profile__group,
.user-profile__sheet,
.user-profile__status,
.user-profile__role {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    color: #555;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 8px 12px;
    transition: background-color 0.3s ease;
}

.user-profile__login:hover,
.user-profile__group:hover,
.user-profile__sheet:hover,
.user-profile__status:hover,
.user-profile__role:hover {
    background-color: #f1f1f1;
}

/* Заголовки (например, "Логин", "Лист") */
.user-profile__login span,
.user-profile__group span,
.user-profile__sheet span,
.user-profile__status span,
.user-profile__role span {
    font-weight: bold;
    color: #333;
    margin-right: 10px;
}

/* Значения (например, {user.username}) */
.user-profile__login p,
.user-profile__group p,
.user-profile__sheet p,
.user-profile__role p {
    margin: 0 !important;
    color: #777 !important;
    text-align: right !important;
    word-break: break-word !important;
}

/* Контейнер статусов */
.user-profile__status {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Список статусов */
.user-profile__status-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

/* Элемент списка статусов */
.user-profile__status-item {
    background-color: #e0f7fa;
    color: #00796b;
    font-size: 0.9rem;
    padding: 5px 10px;
    border-radius: 12px;
    border: 1px solid #b2dfdb;
    white-space: nowrap;
}

.user-profile__status-item:hover {
    background-color: #afdce2;
    cursor: pointer;
}

.user-profile__edit {
    display: flex;
    align-items: center;
    gap: 5px;
}

.user-profile__input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 1rem;
    flex: 1;
    width: 100%;
}

.user-profile__edit-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    color: #007bff;
}

.user-profile__save,
.user-profile__cancel {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: #007bff;
}

.user-profile__save:hover,
.user-profile__cancel:hover {
    color: #0056b3;
}

/* Большие экраны */
@media (min-width: 1025px) {
}

/* Ноутбуки */
@media (max-width: 1024px) {
}

/* Планшеты */
@media (max-width: 768px) {
    .user-profile__name{
        font-size: 1.3em;
    }

    .user-profile__login,
    .user-profile__group,
    .user-profile__sheet,
    .user-profile__status,
    .user-profile__role {
        font-size: 0.95rem;
    }

    .user-profile__status-item {
        font-size: 0.85rem;
    }

}

@media (max-width: 600px) {
    .user-profile__name{
        font-size: 1.2em;
    }

    .user-profile__login,
    .user-profile__group,
    .user-profile__sheet,
    .user-profile__status,
    .user-profile__role {
        font-size: 0.9rem;
        padding: 7px 11px;
    }

    .user-profile__status-item {
        font-size: 0.8rem;
    }
}

/* Мобильные устройства */
@media (max-width: 480px) {
    .user-profile__name{
        font-size: 1.1em;
    }

    .user-profile__login,
    .user-profile__group,
    .user-profile__sheet,
    .user-profile__status,
    .user-profile__role {
        font-size: 0.85rem;
        padding: 6px 10px;
    }

    .user-profile__status-item {
        font-size: 0.75rem;
    }
}