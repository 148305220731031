/* .autocomplete-input {
    margin-bottom: 15px;
    position: relative;
} */

.autocomplete-input {
    margin-bottom: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.chips {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin: 10px 0;
}

.chip {
    background: #e0e0e0;
    padding: 6px 12px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.chip p {
    padding: 0;
}

.chip button {
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
    font-size: 1.2em;
    padding: 0;
}

.input-place {
    /* width: 100%; */
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: white;
}

.dropdown {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 150px;
    overflow-y: auto;
    /* position: absolute; */
    /* width: 100%; */
    background: white;
    z-index: 1000;

    max-width: 200px;
}

.dropdown li {
    padding: 10px;
    cursor: pointer;
}

.dropdown li:hover {
    background: #f0f0f0;
}

/* Большие экраны */
@media (min-width: 1025px) {}

/* Ноутбуки */
@media (max-width: 1024px) {}

/* Планшеты */
@media (max-width: 768px) {

    .chip p {
        font-size: 0.9em;
    }
}

@media (max-width: 600px) {
    .chip p {
        font-size: 0.85em;
    }
}

/* Мобильные устройства */
@media (max-width: 480px) {

    .chip p {
        font-size: 0.8em;
    }
}