.workspace {
    padding: 10px 40px 20px
}

.task-panel {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 15px;
    margin-bottom: 20px;
    padding: 15px;
}

.task-panel__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    width: 100%;
    gap: 14px;
}

.task-panel__label select,
.task-panel__label div {
    width: 80%;
}

.task-panel__label-desc .quill,
.task-panel__label-desc .quill div {
    width: 100%;
}

.tasks-wrapper {
    overflow-x: auto;
    border: 1px solid #ddd;
    border-radius: 10px;
}

.tasks-wrapper-table {
    width: 100%;
    /* border-collapse: separate; */
    /* Убираем объединение границ */
    border-spacing: 0;
    /* Зазоры между ячейками */
    border-collapse: collapse;
}

.tasks-wrapper-table th:first-child,
.tasks-wrapper-table td:first-child {
    border-left: none;
    /* Убираем левую границу */
}

.tasks-wrapper-table th:last-child,
.tasks-wrapper-table td:last-child {
    border-right: none;
    /* Убираем правую границу */
}

.tasks-wrapper-table thead tr:first-child th {
    border-top: none;
    /* Убираем верхнюю границу */
}

.tasks-wrapper-table tbody tr:last-child td {
    border-bottom: none;
    /* Убираем нижнюю границу */
}

.tasks-wrapper-table th,
.tasks-wrapper-table td {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.task-header th {
    background-color: #f5f5f5;
    /* Цвет фона */
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    /* Граница вокруг заголовка */
    padding: 10px;
}
.button-task-title {
    display: flex;
    align-items: center;
    gap: 14px;
    min-width: 240px;
}

.task-cell.title.level-0 {
    padding-left: 30px;
    text-align: left;
}

.task-cell.title.level-1 {
    padding-left: 50px;
    text-align: left;
}

.task-cell.title.level-2 {
    padding-left: 70px;
    text-align: left;
}

.task-wrapper.level-2 {
    font-size: 0.95em;
}

.task-cell-title {
    margin: 0 4px;
}
/* .task-list tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
   
} */

/* .task-list tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
   
} */

/* .task-header th:first-child {
    border-top-left-radius: 10px;
    
} */

/* .task-header th:last-child {
    border-top-right-radius: 10px;
   
} */

.task-header {
    /* display: flex; */
    padding: 10px;
    background-color: aliceblue;
    font-weight: bold;
    /* border-bottom: 2px solid #ddd;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px; */
}

.updated-sort,
.tag-sort {
    width: 10%;
}

.assigner-sort,
.executor-sort {
    width: 15%;
}

.add-task {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    gap: 12px;
}

.task-sort {
    cursor: pointer;
    text-align: center;
    padding: 10px;
    border-left: 1px solid;
    border-right: 1px solid;
    background-color: aliceblue;
    color: #000;
    border: 1px solid #ddd;
}

.task-sort.active-sort {
    background-color: #f0f8ff;
    color: #000;
    font-weight: bold;
}

.arrow {
    font-size: 12px;
    /* Размер стрелки */
    margin-left: 5px;
    /* Отступ от текста */
    color: #007acc;
    /* Цвет стрелки (настраиваемый) */
    vertical-align: middle;
    /* Выравнивание стрелки с текстом */
}

.task-header:hover,
.task-wrapper:hover {
    background-color: inherit;
}

.subtask-sort {
    flex: 1;
    cursor: pointer;
    text-align: center;
    padding: 10px;
    border-left: 1px solid;
    border-right: 1px solid;
}

.task-sort:hover {
    background-color: aliceblue;
}

.subtask-sort:hover {
    background-color: #e0e0e0;
}

.task-row {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.task-row:hover {
    background-color: #f1f1f1;
}

.task-wrapper {
    /* display: flex; */
    align-items: center;
    padding: 10px;
    /* border-bottom: 1px solid #ddd; */
}

.subtask-row {
    /* display: flex; */
    align-items: center;
    padding: 10px;
    /* border-bottom: 1px solid #ddd; */
    background-color: #f9f9f9;
    text-align: left;
}

.subtask-row:hover {
    background-color: #e0e0e0;
}

.task-cell {
    padding: 10px;
    text-align: left;
}

.task-cell.title {
    cursor: pointer;
}

.task-cell.title:hover {
    text-decoration: underline;
}

.task-cell.subtask {
    color: #555;
    font-size: 0.9em;
}

.task-cell.title.subtask {
    cursor: pointer;
}

.task-cell.title.subtask:hover {
    text-decoration: underline;
}

/* Стили для блока комментариев в задачах */
.comments-list {
    width: 90%;
    background-color: #fff;
    border-radius: 5px;
    margin: 15px 0;
    outline: 1px solid #fff;
}

.btn-overlay-edit {
    background-color: unset;
    color: #555;
    cursor: pointer;
    padding: 0;
}

.btn-overlay-edit:hover {
    background-color: unset;
    text-decoration: underline;
    color: #000;
}

.btn-overlay-edit:active {
    background-color: unset;
    color: gray;
}

.btn-overlay-save {
    background-color: hsl(127.25deg 43.13% 41.37%);
    ;
}

.btn-overlay-save:hover {
    background-color: hsl(127.25deg 43.13% 48.37%);
    ;
}

.btn-overlay-save:active {
    background-color: hsl(127.25deg 43.13% 31.37%);
    ;
}

/* button:active:active {
    background-color: #24592a;
} */

.btn-overlay-cancel {
    background-color: hsl(0deg 0% 33.33%);
}

.btn-overlay-cancel:hover {
    background-color: hsl(0, 0%, 40%);
}

.btn-overlay-cancel:active {
    /* background-color: unset; */
    background-color: hsl(0deg 0% 23.33%);
}

.btn-block {
    display: flex;
    align-items: self-start;
    flex-basis: 100%;
    gap: 10px;
}

.icon-container {
    width: 20px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.project-td {
    color: #525c69;
    text-align: left;
    font-weight: 700;
    cursor: pointer;
}



.project-row:hover td {
    /* all: unset; */
    /* background-color: unset; */
    text-decoration: underline;
}

.show-tasks-button {
    margin-right: 20px;
}

.task-cell.updated-at {
    white-space: nowrap;
}

.button-subtask {
    box-shadow: 0 5px 15px 0 rgba(240, 248, 255, 1);
    transition: 0.5s;
    cursor: pointer;
    background-color: rgb(196, 206, 215);
    width: 20px;
}

.button-subtask:hover {
    box-shadow: 0 5px 15px 0 rgba(207, 222, 236, 1);
    transition: 0.5s;
    background-color: rgb(207, 222, 236);
}

.button-subtask:active {
    background-color: rgb(151, 154, 158);
}

/* Стили для модального окна */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-wrapper {
    position: relative;
    padding: 20px;
    width: 90%;
    height: 80%;
    border-radius: 20px;
    background: #eef2f4;
    overflow-y: auto;
}

.modal-wrapper__bottom {
    display: flex;
    /* justify-content: space-evenly;
    flex-wrap: wrap; */
    align-items: stretch;
    padding: 10px 24px;
    border-radius: 20px;
    gap: 20px;
}

.pagetitle-menu {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: flex-start;
    flex-grow: 1;
}

.pagetitle__wrapper {
    position: relative;
    display: flex;
    gap: 10px;
}

.pagetitle {
    align-items: flex-start;
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.menu-button {
    row-gap: 10px;
    background-color: #55a8f0;
    color: #fff;
    border-radius: .4rem;
    border: 0;
    padding: 6px;
}

.task-template {
    width: 40px;
    height: 40px;
    position: relative;
    cursor: pointer;
    border: none;
    background: transparent;
}

.task-template::before,
.task-template::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 80%;
    height: 3px;
    background-color: black;
    transform-origin: center;
    transition: transform 0.3s ease;
}

.task-template::before {
    transform: translateY(-50%) rotate(0deg);
}

.task-template::after {
    transform: translateY(-50%) rotate(90deg);
}

.task-template.active::before {
    transform: translateY(-50%) rotate(45deg);
}

.task-template.active::after {
    transform: translateY(-50%) rotate(-45deg);
}

.template-list {
    position: absolute;
    top: 100%;
    margin: 0;
    padding: 0;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #f9f9f9;
    list-style: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
}

.template-item {
    padding: 10px 15px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    transition: background 0.3s;
}

.template-item:last-child {
    border-bottom: none;
}

.template-item:hover {
    background: #ececec;
}


.menu-button:hover {
    background-color: hsl(207.87deg 83.78% 70.73%);
}

.menu-button:active {
    background-color: hsl(207.87deg 83.78% 53.73%);
}

.close-wrapper {
    display: flex;
    justify-content: space-between;
}

.close-button {
    position: static;
    font-size: 20px;
    cursor: pointer;
}

.modal-subtask {
    position: fixed;
    left: 25%;
    margin-top: 20px;
    width: 50%;
    background-color: #fff;
    z-index: 1001;
    border: 1px solid #ddd;
    border-radius: 5px;
    max-height: 72vh;
    overflow-y: auto;
}

.description {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 65%;
    border-radius: 20px;
}

.description-comment {
    position: relative;
    outline: 2px solid white;
    border-radius: 5px;
    width: 100%;
    background: white;
    margin-bottom: 15px;
}

.task-detail-header {
    display: flex;
    justify-content: space-between;
    padding: 14px;
    border-bottom: 1px solid #e9eaec;
    gap: 20px;
    align-items: center;
    font-size: 0.95em;
}

.task-panel__input {
    width: 80%;
}

.task-detail-header-menu {
    display: flex;
    align-items: center;
    row-gap: 10px;
    column-gap: 20px;
    flex-grow: 1;
    justify-content: space-between;
}

.task-detail-subtitle-status {
    /* margin-right: auto !important; */
    text-align: left;
}

.task-detail-btn {
    background: none;
    border: none;
    color: blue;
    text-decoration: underline;
    padding: 0px;
    text-align: left;
}

.if-no {
    display: inline-block;
    cursor: pointer;
    opacity: .7;
    color: gray;
    position: relative;
}

.if-not-no {
    display: none;
}

.description-section {
    text-align: left;
    border-bottom: 1px solid rgba(234, 235, 237, .78);
    max-height: 600px;
    overflow-y: auto;
    padding: 14px;
}

.description-section p {
    white-space: pre-wrap;
    padding: 10px;
    border-radius: 5px;
    margin: 5px 0;
}

.description-section textarea {
    resize: vertical;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 96%;
    margin: 10px;
}

.info-block {
    display: flex;
    flex-direction: column;
    width: 35%;
    border-radius: 5px;
    background: white;
}

.status-block {
    padding: 10px;
    background-color: #eef2f4;
    outline: 1px solid #eef2f4;
    border-radius: 8px;
    margin-bottom: 10px;
}

.status-block label {
    /* display: inline; */
    font-weight: inherit;
    color: inherit;
}

.status-block p {
    color: #004d40;
    font-size: 1em;
    margin: 5px 0 0;
}

.timing {
    text-align: left;
}

.time-to-status {
    font-weight: normal;
    color: #fff;
}

.modal-wrapper p {
    margin: 0;
    font-size: 1rem;
    color: black;
}

.modal-content button {
    margin-top: 15px;
}

.title-edit-container {
    display: flex;
    align-items: center;
    text-align: start;
}

.edit-icon {
    margin-left: 10px;
    cursor: pointer;
    font-size: 0.9em;
    color: #555;
}

.edit-icon:hover {
    color: #000;
}

.title-edit-container button {
    margin-left: 5px;
    padding: 5px 10px;
    font-size: 0.9em;
    cursor: pointer;
}

.comments-section__task input {
    width: 100%;
    border: none;
    cursor: pointer;
    background-color: inherit;
}

.comments-section__task {
    margin-top: 5px;
    width: 100%;
    background-color: #fff;
}

.comments-btn {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
}

.subtask-wrapper {
    outline: 1px solid white;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 15px;
    background-color: white;
}

.subtask-wrapper-header {
    padding: 15px;
    text-align: left;
    /* font-size: 0.8rem; */
}

.result-section {
    border-radius: 5px;
    width: 100%;
    margin-bottom: 15px;
    background-color: #f8fbeb;
}

.result-wrapper {
    padding: 15px;
}

.comment-result {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 10px 15px 10px 10px;
}

.subtask-title-list {
    font-weight: bold;
    margin: 0 0 20px;
}

.check-list-wrapper {
    text-align: start;
    border-bottom: 1px solid rgba(234, 235, 237, .78);
    padding: 14px;
}

.people-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    padding: 14px;
    border-bottom: 1px solid #eaebedc7;
}

.main-data-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    padding: 14px;
    border-bottom: 1px solid #eaebedc7;
}

.secondary-data-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    padding: 14px;
    border-bottom: 1px solid #eaebedc7;
}

.task-panel__button {
    padding: 14px;
    text-align: start;
}

.check-list-wrapper p:hover {
    text-decoration: underline;
    cursor: pointer;
}

.task-detail-extra {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid rgba(234, 235, 237, .78);
    padding: 14px;
    gap: 12px;
}

.copy-link-task {
    display: inline-flex;
    align-items: center;
}

.copy-link-emoji {
    cursor: pointer;
    font-size: 20px;
}

.copy-link-task .copy-link-emoji:hover {
    transform: scale(1.2);
}

.task-detail-group,
.task-detail-maintask {
    color: #555;
    text-align: left;
    display: flex;
    column-gap: 8px;
    flex-wrap: wrap;
    row-gap: 4px;
}

/* .task-detail-maintask {
    color: #555;
} */


.details-timer {
    /* border-bottom: 1px solid rgba(234, 235, 237, .78); */
    /* padding: 14px 14px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.reset-button {
    background-color: unset;
    transition: 0.3s ease-in-out;
}

.task-view-buttonset {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin: 14px;
    flex-wrap: wrap;
}

.task-view-buttonset button:hover {
    cursor: pointer;
}

.dropdown-button {
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    background: gray;
    border-radius: .4rem .4rem 0 0;
}

.dropdown-more {
    position: relative;
    padding-right: 25px;
    width: 77px;
    cursor: pointer;
    background: none;
}

.dropdown-button:hover {
    background: rgb(150, 149, 149);
}

.dropdown-more:active {
    background: none;
}

.dropdown-button:active {
    background: rgb(95, 95, 95);
}

.dropdown-more::after {
    content: '';
    position: absolute;
    right: 10px;
    width: 10px;
    height: 10px;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(135deg);
}

.dropdown-menu button {
    cursor: pointer;
    background: none;
}

.dropdown-menu button:hover {
    text-decoration: underline;
    background: rgb(150, 149, 149);
}

.dropdown-menu {
    position: absolute;
    top: 105%;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding: 5px 0;
    background: gray;
    margin-top: -2px;
    border-radius: 0 0 .4rem .4rem;
    width: 100%;
}

.data-card {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 10px;
    gap: 20px;
}

.deadline-task input {
    width: unset;
    max-width: 200px;
}

.members {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
}

.members label {
    border-bottom: 1px solid #eaebedc7;
}

.members p {
    padding: 10px;
    color: #0b66c3;
    cursor: pointer;
}

.assigner-block,
.executor-block,
.observers-block,
.tags-block {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
}

.timing__hr {
    border: none;
    height: 1px;
    background-color: #eaebedc7;
    margin: 10px;
    width: 100%;
}

.updated-task {
    padding: 20px;
    border-radius: 5px 5px 0 0;
    background-color: #5fb3f7;
}

.updated-task p {
    margin: 0;
    color: #fff;
    font-size: inherit;
    font-weight: 600;
}

.updated-task label {
    color: inherit;
}

.info-block__bottom {
    padding: 20px;
}

.deadline_task {
    border-bottom: 1px solid #eaebedc7;
}

.task-info-panel-important {
    display: inline-block;
    cursor: pointer;
    font-size: 0.9em;
    text-align: center;
    white-space: nowrap;
    transition: background-color 0.3s;
}

.task-info-panel-important:hover {
    opacity: 0.8;
}

select {
    width: 100%;
    font-size: 1.1em;
}

.task-header th {
    font-size: 0.9em;
    border-left: 1px solid #ddd;
    text-align: center;
}

/* .workspace {
    padding: 10px;
} */

select {
    font-size: 1em;
}



.project-row,
.project-td,
.task-wrapper,
.task-cell,
.subtask-row {
    /* border: 1px solid #ddd; */
    padding: 12px;
}

.task-cell.tytle {
    border-left: none;
}

.project-row,
.task-wrapper,
.subtask-row {
    color: white;
}

.project-td,
.task-cell {
    background-color: #f9f9f9;
}

.project-row:nth-child(even) .project-td,
.task-wrapper:nth-child(even) td {
    background-color: #f2f2f2;
}

.project-row:hover .project-td,
.task-wrapper:hover td,
.subtask-row:hover td {
    background-color: #ddd;
    cursor: pointer;
}

.members .task-panel__label{
    display: none;
}

.task-detail-group .task-panel__label{
    display: none;
}

.task-detail-maintask .task-panel__label{
    display: none;
}

.view-mode-switch {
    margin-bottom: 10px;
    display: flex;
    gap: 20px;
    justify-content: center;
}


.projects-list {
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
}

.project-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: left;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    align-items: center;
}

.project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);

}

.project-card h2 {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
    margin: 0;
    transition: color 0.3s ease;
}

.project-card:hover h2{
    color: #007bff;
}

.project-card p {
    font-size: 1rem;
    color: #777;
    margin-top: 10px;
    flex-grow: 1;
}

.info-button {
    padding: 0;
    background-color: transparent;
    display: flex;
    color: #000;
    cursor: pointer;
    position: relative;
}

.tooltip-text {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.info-button:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.view-mode-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 30px;
    row-gap: 20px;
    flex-wrap: wrap;
    margin: 36px 0 20px 0;
}

.name-info {
    display: flex;
    gap: 20px;
    align-items: center;
}

.button-out {
    background-color: transparent;
    color: #000; 
    transition: all 0.3s ease;
    font-weight: 500;

}

.button-out:hover {
    text-decoration: underline;
}

.search-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    width: 100%;
    max-width: 500px; 
    margin: 0 auto; 
    box-sizing: border-box;
    margin-bottom: 20px;
}

.search-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.search-results {
    margin-top: 10px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    max-height: 400px;
    overflow-y: auto;
    width: 100%; 
    box-sizing: border-box;
}

.search-result-item {
    padding: 10px;
    cursor: pointer;
}

.search-result-item:hover {
    background-color: #f5f5f5;
}

.search-result-item h4 {
    margin: 0;
    font-size: 16px;
}

.search-result-item span {
    font-size: 12px;
    color: gray;
}

.show-more-button {
    background-color: #fff;
    color: #333;
    cursor: pointer;
    font-weight: 600;
    background-color: transparent;
}


/* Большие экраны */
@media (min-width: 1025px) {}

/* Ноутбуки */
@media (max-width: 1024px) {
    .task-detail-header-menu {
        align-items: flex-start;
        flex-direction: column;
    }

    .task-detail-header {
        align-items: flex-start;
    }

    .workspace {
        padding: 10px 30px 20px
    }

    .project-td {
        font-size: 1em;
    }

    .task-cell {
        font-size: 0.95em;
    }
}

/* Планшеты */
@media (max-width: 768px) {
    .task-header th {
        font-size: 0.9em;
    }

    .workspace {
        padding: 10px 20px 20px
    }

    select {
        font-size: 1em;
    }

    .task-detail-header-menu {
        align-items: center;
        flex-direction: row;
    }

    .task-detail-header {
        align-items: center;
    }

    .modal-wrapper p,
    .task-detail-btn {
        font-size: 0.9rem;
    }

    .modal-wrapper__bottom {
        flex-direction: column;
    }

    .description {
        width: 100%;
    }

    .info-block {
        width: 100%;
    }

    .modal-subtask {
        left: 20%;
        width: 60%;
    }

    .updated-task {
        padding: 18px;
    }

    .project-t d {
        font-size: 0.95em;
    }

    .task-cell {
        font-size: 0.9em;
    }

    .task-cell.subtask {
        font-size: 0.85em;
    }

    .project-row,
    .project-td,
    .subtask-row,
    .task-cell,
    .task-wrapper {
        padding: 11px;
    }

    .task-cell.updated-at {
        white-space: wrap;
    }

    .project-card h2 {
        font-size: 1.15rem;
    }
}

@media (max-width: 600px) {
    .task-header th {
        font-size: 0.85em;
    }

    .workspace {
        padding: 10px 10px 20px
    }

    .task-panel__label {
        font-size: 0.9em;
    }

    select {
        font-size: 0.9em;
    }

    .task-info-panel-important {
        font-size: 0.8em;
    }

    .modal-wrapper p,
    .task-detail-btn {
        font-size: 0.85rem;
    }

    .task-detail-header-menu {
        align-items: flex-start;
        flex-direction: column;
    }

    .task-panel__label {
        align-items: flex-start;
        flex-direction: column;
    }

    .task-detail-header {
        align-items: flex-start;
    }

    .modal-wrapper__bottom {
        padding: 10px 14px;
    }

    .copy-link-emoji {
        font-size: 18px;
    }

    .modal-subtask {
        left: 15%;
        width: 70%;
    }

    .updated-task {
        padding: 16px;
    }

    .project-td {
        font-size: 0.9em;
    }

    .task-cell {
        font-size: 0.85em;
    }

    .task-cell.subtask {
        font-size: 0.8em;
    }

    .project-row,
    .project-td,
    .subtask-row,
    .task-cell,
    .task-wrapper {
        padding: 10px;
    }

    .members label {
        font-size: 0.95em;
    }

    .task-cell-title {
        margin: 0 2px;
    }

    .project-card h2 {
        font-size: 1.1rem;
    }

    .info-button svg {
        width: 22px;
        height: 22px;
    }

    .project-card {
        padding: 18px;
    }

    .search-input {
        font-size: 14px;
        padding: 9px;
    }
    
    .search-result-item h4 {
        font-size: 14px;
    }

    .search-result-item span {
        font-size: 11px;
    }

    .search-result-item {
        padding: 9px;
    }
}

/* Мобильные устройства */
@media (max-width: 480px) {
    .task-header th {
        font-size: 0.8em;
    }

    .task-panel__label {
        font-size: 0.8em;
    }

    select {
        font-size: 0.8em;
    }

    .modal-wrapper p,
    .task-detail-btn {
        font-size: 0.8rem;
    }

    .modal-wrapper__bottom {
        padding: 10px 0px;
    }

    .pagetitle-menu {
        flex-direction: column;
    }

    .copy-link-emoji {
        font-size: 16px;
    }

    .modal-subtask {
        left: 10%;
        width: 80%;
    }

    .updated-task {
        padding: 16px;
    }

    .project-td {
        font-size: 0.85em;
    }

    .task-cell {
        font-size: 0.8em;
    }

    .task-cell.subtask {
        font-size: 0.75em;
    }

    .project-row,
    .project-td,
    .subtask-row,
    .task-cell,
    .task-wrapper {
        padding: 9px;
    }

    .members label {
        font-size: 0.9em;
    }

    .task-cell-title {
        margin: 0;
    }

    .project-card h2 {
        font-size: 1rem;
    }

    .info-button svg {
        width: 20px;
        height: 20px;
    }

    .project-card {
        padding: 16px;
    }

    .search-input {
        font-size: 12px;
        padding: 8px;
    }

    .search-result-item h4 {
        font-size: 12px;
    }

    .search-result-item span {
        font-size: 10px;
    }

    .search-result-item {
        padding: 8px;
    }
}