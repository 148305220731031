* {
  font-family: Inter;
  color: #183b59;
}

.logo-container {
  text-align: center;
}

.logo {
  /* width: 350px; */
  /* Установите нужный размер логотипа */
  height: auto;
  width: 190px;
  padding-left: 20px;
}

.logo-login {
  width: 350px;
  height: auto;
}

.proof-editor-row {
  margin: 5px 0;
}

.login {
  background-image: url('/assets/orange.png');
  background-repeat: repeat;
  background-size: auto;
  background-color: #fff0e4;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 1);
  text-align: center;
  border: 2px solid #ff790b;
  padding: 10px;
  margin: 100px;
  border-radius: 15px;
  z-index: 1;
}

.login input {
  background: #f6f6f6;
  border: 1px solid #cecece;
  border-radius: 8px;
  font-size: 13px;
  padding: 6px 0 4px 10px;
  width: 200px;
  max-width: 100%;
  box-sizing: border-box;
}

.notifications-logout {
  display: flex;
  align-items: center;
  gap: 10px;
  /* расстояние между иконкой и кнопкой */
}

.header {
  display: flex;
  /* align-items: center;
  justify-content: end; */
  margin: 20px;
  justify-content: space-between;
  align-items: flex-start;
}

.user-info {
  font-size: 1em;
  color: white;
  background-color: #999;
  padding: 10px 20px;
  border-radius: 10px;
  display: inline-block;
  font-weight: bold;
  white-space: wrap;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.user-info:hover {
  background-color: #555;
  /* Изменение цвета фона при наведении */
}

.user-info__modal {
  display: flex;
  flex-direction: column;
  align-items: end;
  padding-right: 20px;
}

.user-info__btn {
  background-color: transparent;
  border: none;
  border-radius: inherit;
  font-weight: 700;
  font-size: 1rem;
  cursor: pointer;
}

.user-info__btn:hover {
  text-decoration: underline;
  background-color: transparent;
}

.user-info__btn:active {
  background-color: unset;
  background-color: transparent !important;
}

.user-info__btn:focus {
  background-color: unset;
  background-color: transparent !important;
}

/* Медиазапросы для адаптации под мобильные устройства */
@media (max-width: 768px) {
  .menu {
    padding: 5px 0;
    /* Уменьшаем отступы */
  }
}

body {
  background-image: url('/assets/background.png');
  background-repeat: repeat;
  background-size: auto;
}

.eom-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.eom-list__item {
  display: flex;
  gap: 10px;
  width: 100%;
}

.eom-title {
  margin: 0;
  margin-right: auto;
  color: #fff;
  font-weight: 600;
}

.eom-list__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 12px;
  width: 100%;
  border-radius: .4rem;
  border: 0;
  background-color: #55a8f0;
}

.eom-list__button:hover {
  background-color: #3377b3;
}

.eom-button {
  /* width: 320px; */
  /* Задайте подходящую ширину */
  /* height: 50px; */
  /* Задайте подходящую высоту */
  width: 100%;
  border-radius: .4rem;
  border: 0;
  padding: 12px;
  /* margin: 4px; */
  background-color: #55a8f0;
}

.eom__button-delete {
  width: auto;
  background-color: #f10a0a;
}

button:disabled {
  background-color: grey;
  color: white;
  cursor: not-allowed;
}

.eom-button.stopped {
  background-color: red;
  color: white;
}

.status-wrapper {
  text-align: center;
  background-color: #f9f9f9;
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
}

.status-button {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.tsok-comment {
  background-image: url('/assets/orange.png');
  background-repeat: repeat;
  background-size: auto;
  background-color: #fff0e4;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 1);
  text-align: center;
  border: 2px solid #ff790b;
  padding: 10px;
  margin: 50px 0;
  border-radius: 15px;
  z-index: 1;
  width: 90%;
}

.selected {
  background-color: #3c9747;
  font-weight: bold;
}

.selected-eom-container {
  background-image: url('/assets/blue.png');
  background-repeat: repeat;
  background-size: auto;
  background-color: #e2f0ff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 1);
  text-align: center;
  border: 2px solid #007bff;
  padding: 10px;
  margin: 50px 0;
  border-radius: 15px;
  z-index: 1;
  position: relative;
  width: 90%;
}

.eom-header {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 8px;
}

.eom-header p {
  margin: 0;
  flex-grow: 1;
}

.edit-icon {
  cursor: pointer;
  /* margin-left: 10px; */
  font-size: 1.2em;
  color: #007bff;
  right: 10px;
  top: 10px;
}

.error {
  color: red;
  font-weight: bold;
}

button {
  color: #fff;
  border-radius: .4rem;
  border: 0;
  padding: 6px;
  cursor: pointer;
}

.button {
  background-color: hsl(207.87deg 83.78% 63.73%);
  background-color: #55a8f0;
  color: #fff;
  border-radius: .4rem;
  border: 0;
  padding: 6px;
  /* margin: 4px; */
}

/* .button:hover {
  background-color: #3377b3;
} */

.button:hover {
  background-color: hsl(207.87deg 83.78% 70.73%);
}

.button:active {
  background-color: hsl(207.87deg 83.78% 53.73%);
}

/* .button:active {
  background-color: #3377b3;
} */

.eom-button:hover {
  background-color: #3377b3;
}

.eom__button-delete:hover {
  background-color: #c80a0a;
}

/* .eom-button:active {
  background-color: #3377b3;
} */


input {
  width: 200px;
  /* font-size: 13px; */
  padding: 6px 0 4px 10px;
  border: 1px solid #cecece;
  background: #F6F6f6;
  border-radius: 8px;
}

.status {
  font-weight: bold;
  padding: 5px;
  border-radius: 4px;
}

.status.ready {
  color: green;
}

.status.draft {
  color: gray;
}

.status.in-progress {
  color: green;
}

.status.video {
  color: #0c6ed6;
}

.status.audio {
  color: #ed1e79;
}

.status.graphic {
  color: #ff790b;
}

.status.editor {
  color: #ff3fff;
}

.status.backeditor {
  color: #ffa500;
}

.status.tzready {
  color: #7e028e;
}

.status.montazh {
  color: #0000ff;
}

.status.chernovyk {
  color: #696969;
}

.status.rereading {
  color: #9e09b1;
}

@font-face {
  font-family: 'Inter';
  src: url(../../fonts/Inter-V.ttf);
}

.change-log-wrapper {
  display: flex;
  justify-content: center;
  /* Центрируем таблицу по горизонтали */
  width: 100%;
  margin: 0 auto;
  /* Центрируем контейнер таблицы */
}

.change-log {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.change-log__title {
  margin: 0;
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.button-wrapper {
  display: flex;
  gap: 10px;
}

.table-container {
  width: 100%;
  max-width: 1200px;
  /* Максимальная ширина контейнера */
  overflow-x: auto;
  /* Прокрутка для таблицы */
  /* padding: 10px; */
  margin: 0 auto;
  /* Центрирование контейнера */
}

.stats-page {
  margin: 0 20px;
  padding-bottom: 20px;
}

.table-container-stats,
.table-container-change {
  width: 100%;
  max-width: 1200px;
  overflow-x: auto;
  /* padding: 10px; */
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

.table-container-change {
  margin-top: 20px;
}

.table-container-stats table,
.table-container-change table {
  border-collapse: collapse;
  margin: 0 auto;
  min-width: 300px;
  width: auto;
}

.table-container-stats th,
.table-container-stats td,
.table-container-change th,
.table-container-change td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}

.table-container-stats th,
.table-container-change th {
  background-color: #4CAF50;
  color: white;
}

.table-container-stats td,
.table-container-change td {
  background-color: #f9f9f9;
}

.table-container-stats tr:nth-child(even) td,
.table-container-change tr:nth-child(even) td {
  background-color: #f2f2f2;
}

.table-container-stats tr:hover td,
.table-container-change tr:hover td {
  background-color: #ddd;
  cursor: pointer;
}

/* .change-log table {
  width: 100%;
  min-width: 600px;
  border-collapse: collapse;
  margin: 0 auto;
}

.change-log th,
.change-log td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.change-log th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #4CAF50;
  color: white;
}

@media only screen and (max-width: 768px) {

  .change-log th,
  .change-log td {
    padding: 8px;
    font-size: 12px;
  }

  .change-log table {
    font-size: 12px;
  }
} */


.updatelist {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin: 20px 20px;
}

.updatelist h2 {
  color: #333;
  margin-bottom: 10px;
}

.updatelist h3 {
  color: #555;
  margin-bottom: 5px;
}

.updatelist p {
  color: #666;
  line-height: 1.6;
  text-align: left;
}

.top-tsok-page {
  text-align: center;
  padding: 20px;
}

.top-tsok-page {
  text-align: center;
  padding: 20px;
}

/* .change-log {
  margin-top: 20px;
  overflow-x: auto;

}

.change-log table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;

}

.change-log th,
.change-log td {
  border: 1px solid #ddd;
  padding: 8px;
}

.change-log th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4CAF50;
  color: white;
}

@media (max-width: 768px) {

  .change-log th,
  .change-log td {
    font-size: 12px;
    padding: 4px;
  }

  .change-log table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
} */

.link_bitrix .bitrix-button {
  background-color: hsl(28.68deg 100% 59.8%);
}

.link_bitrix_tsok .bitrix-button {
  background-color: hsl(122.42deg 39.44% 49.22%);
}

.link_bitrix_tsok .bitrix-button:hover {
  background-color: hsl(122.42deg 39.44% 56.22%);
}

.link_bitrix_tsok .bitrix-button:active {
  background-color: hsl(122.42deg 39.44% 39.22%);
}

.link_bitrix .bitrix-button:hover {
  background-color: hsl(28.68deg 100% 66.8%);
  /* Цвет при наведении */
}

.link_bitrix .bitrix-button:active {
  background-color: hsl(28.68deg 100% 49.8%);
  /* Цвет при наведении */
}

.link_bitrix .bitrix-button:focus {
  outline: none;
  /* Убираем обводку при фокусе */
}

/* Стили для поля ввода комментария к ЦОКу */
.tsok-comment-input {
  width: 200px;
  font-size: 13px;
  padding: 6px 0 4px 10px;
  border: 1px solid #cecece;
  background: #F6F6f6;
  border-radius: 8px;
  transition: width 0.3s ease;
  /* Плавное изменение ширины */
}

/* При фокусе увеличиваем ширину только для поля с классом tsok-comment-input */
.tsok-comment-input:focus {
  width: 400px;
  /* Увеличиваем ширину */
}

/* Стили для поля ввода комментария к EOMy */
.eom-comment-input {
  width: 200px;
  font-size: 13px;
  padding: 6px 0 4px 10px;
  border: 1px solid #cecece;
  background: #F6F6f6;
  border-radius: 8px;
  transition: width 0.3s ease;
  /* Плавное изменение ширины */
}

/* При фокусе увеличиваем ширину только для поля с классом eom-comment-input */
.eom-comment-input:focus {
  width: 400px;
  /* Увеличиваем ширину */
}

.search {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 460px;
}

.search-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
}

.comment-add {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.add-eom-modal {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.tsok-comment-edit {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.quill {
  word-break: break-word;
}

.notification_mobile {
  display: flex;
  align-items: center;
}

.period-buttons {
  display: flex;
  justify-content: center;
  gap: 14px;
  /* margin-bottom: 10px; */
  align-items: center;
}

.title-weekly {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  gap: 10px;
  align-items: center;
}

.date-filter-popup {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 10px #0003;
  min-width: 200px;
  padding: 15px;
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.period-buttons button {
  position: relative;
}

.date-filter-popup input {
  width: 120px;
  padding: 6px;
}

/* Большие экраны */
@media (min-width: 1025px) {}

/* Ноутбуки */
@media (max-width: 1024px) {
  h2 {
    font-size: 1.4em;
  }

  h3 {
    font-size: 1.1em;
  }

  .table-container-stats th,
  .table-container-stats td,
  .table-container-change th,
  .table-container-change td {
    padding: 10px;
    font-size: 0.9em;
  }
}

/* Планшеты */
@media (max-width: 768px) {
  .logo {
    height: auto;
    width: 140px;
    padding-left: 0;
  }

  .logo-login {
    width: 300px;
    height: auto;
  }

  .eom-button,
  .eom-list__button {
    padding: 10px;
    font-size: 0.8em;
  }

  .status {
    font-size: 0.95em;
    padding: 4px;
  }

  .tsok-comment {
    font-size: 0.95em;
    margin: 40px 0;
  }

  h2 {
    font-size: 1.3em;
  }

  h3 {
    font-size: 1em;
  }


  .table-container-stats th,
  .table-container-stats td,
  .table-container-change th,
  .table-container-change td {
    padding: 6px 10px;
    font-size: 0.8em;
  }

  .eom-header p {
    font-size: 0.95em;
  }

  .user-info {
    font-size: 0.9em;
    padding: 9px 18px;
  }

  .user-info__btn {
    font-size: 0.95em;
  }

}

@media (max-width: 600px) {

  .tsok-comment,
  .selected-eom-container {
    font-size: 0.9em;
    margin: 30px 0;
  }

  .eom-header p {
    font-size: 0.9em;
  }

  button,
  input {
    font-size: 0.8em;
  }

  .status-button,
  .comment-add,
  .add-eom-modal,
  .eom-header,
  .tsok-comment-edit {
    gap: 8px;
  }

  h3 {
    font-size: 0.95em;
  }

  .login {
    margin: 60px;
  }

  .checkbox-container label {
    font-size: 0.95em;
  }

  .user-info {
    font-size: 0.85em;
    padding: 8px 16px;
  }

  .user-info__btn {
    font-size: 0.95em;
  }

  .header {
    align-items: stretch;
    flex-direction: column;
  }

  .notifications-logout {
    justify-content: space-between;
  }

  .period-buttons svg {
    width: 22px;
    height: 22px;
  }

}

/* Мобильные устройства */
@media (max-width: 480px) {

  .eom-button,
  .eom-list__button {
    padding: 8px;
    font-size: 0.7em;
  }

  .status {
    font-size: 0.9em;
    padding: 3px;
  }

  .tsok-comment,
  .selected-eom-container {
    font-size: 0.8em;
    margin: 30px 0;
  }

  h2 {
    font-size: 1.2em;
  }

  h3 {
    font-size: 0.9em;
  }

  .table-container-stats th,
  .table-container-stats td,
  .table-container-change th,
  .table-container-change td {
    padding: 4px 8px;
    font-size: 0.7em;
  }

  .eom-header p {
    font-size: 0.85em;
  }

  button,
  input {
    font-size: 0.75em;
  }

  .status-button,
  .comment-add,
  .add-eom-modal,
  .eom-header,
  .tsok-comment-edit {
    gap: 6px;
  }

  .login {
    margin: 50px 30px;
  }

  .checkbox-container label {
    font-size: 0.9em;
  }

  .logo {
    height: auto;
    width: 120px;
  }

  .logo-login {
    width: 240px;
    height: auto;
  }

  .user-info {
    font-size: 0.8em;
    padding: 6px 12px;
  }

  .user-info__btn {
    font-size: 0.9em;
  }

  input {
    width: 180px;
  }

  .period-buttons svg {
    width: 20px;
    height: 20px;
  }

  .date-filter-popup input {
    width: 100px;
    padding: 4px;
  }

}