.kanban-board {
    display: flex;
    gap: 10px;
    padding: 10px;
}

.kanban-column {
    background-color: #f0f0f0;
    padding: 10px;
    flex: 1;
    border-radius: 4px;
    min-height: 400px;
}

.kanban-card {
    background-color: #fff;
    padding: 8px;
    margin-bottom: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: grab;
}