/* Основные стили для управления аккаунтами */
.account-management {
  margin: 20px;
}

.account-management-div {
  margin-bottom: 10px;
}

.account-management label {
  display: block;
  margin-bottom: 5px;
}

.account-management input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

/* Стили для списка пользователей */
.account-management ul {
  list-style-type: none;
  padding: 0;
}

.user-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
  gap: 10px;
}

.user-list:hover {
  background-color: #f1f1f1;
}

.user-list span {
  font-weight: bold;
  color: #333;
}

.account-management .edit-form {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.account-management .edit-form h3 {
  margin-bottom: 20px;
}

.account-management .edit-form button {
  margin-right: 10px;
}

.user-management_action-button {
  display: flex;
  column-gap: 15px;
  row-gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.account-management-button {
  padding: 10px 15px;
  color: #fff;
  background-color: hsl(211.06deg 100% 50%);
  border: none;
  /* border-radius: 8px; */
  cursor: pointer;
  /* transition: background-color 0.3s ease; */
  white-space: nowrap;
}

.account-management-button:hover {
  background-color: hsl(211.06deg 100% 57%);
}

.account-management-button:active {
  background-color: hsl(211.06deg 100% 40%);
}

.account-management-user,
.account-management-message {
  font-size: 1em;
}

.additional-options {
  display: flex;
  gap: 5px;
}

.account-management-button.block {
  background: none;
  color: hsl(211.06deg 100% 50%);
  padding: 10px 0;
}

.group-buttons, .role-buttons {
  margin-bottom: 20px;
  gap: 5px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.account-management-button.role {
  background: white;
  color: #55a8f0;
  border: 1px solid #55a8f0;
}

.account-management-button.role.active {
  color: #fff;
  background-color: #55a8f0;
  border: none;
}

.account-management-button.group {
  background: white;
  color: hsl(211.06deg 100% 50%);
  border: 1px solid hsl(211.06deg 100% 50%);
}

.account-management-button.group.active {
  color: #fff;
  background-color: hsl(211.06deg 100% 50%);
  border: none;
}


/* Большие экраны */
@media (min-width: 1025px) {}

/* Ноутбуки */
@media (max-width: 1024px) {}

/* Планшеты */
@media (max-width: 768px) {

  .account-management-user {
    font-size: 0.95em;
  }

}

@media (max-width: 600px) {

  .account-management label,
  .account-management-message {
    font-size: 0.95em;
  }

  .account-management-button {
    padding: 9px 12px;
  }

  .account-management-button.block {
    padding: 9px 0;
  }

  .account-management input {
    padding: 6px 8px;
  }

  .account-management-user {
    font-size: 0.9em;
  }
}

/* Мобильные устройства */
@media (max-width: 480px) {

  .account-management label,
  .account-management-message {
    font-size: 0.9em;
  }

  .account-management-button {
    padding: 8px 10px;
  }

  .account-management-button.block {
    padding: 8px 0;
  }

  .account-management input {
    padding: 5px 8px;
  }

  .account-management-user {
    font-size: 0.8em;
  }

  .user-list {
    flex-direction: column;
  }
}