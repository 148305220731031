.news-container {
    margin: 0 auto;
    padding: 20px;
    max-width: 1200px;
    font-family: Arial, sans-serif;
}

.news-item {
    border: none;
    border-radius: 15px;
    padding: 15px;
    padding-top: 30px;
    margin-bottom: 20px;
    background-color: #fff;
    position: relative;
}

.news-item__wrapper {
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0 0 15px 5px #0080ff80, 0px 5px 15px 0px #ff790b;
}

.news-item__title-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 25px;
}

.news-title {
    margin: 0;
    font-size: 1.8em;
    /* Измените отступ для заголовка */
    color: #333;
    font-weight: bold;
    text-align: left;
    margin-left: 10px;
}

.news-content {
    margin: 0;
    /* margin-bottom: 35px; */
    padding: 0 30px;
    padding-left: 40px;
    font-size: 1.2em;
    text-align: start;
    color: #444;
}

.news-content ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.comments-section {
    /* display: flex;
    flex-direction: column;
    align-items: flex-start; */

    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 15px;
    background-color: #f9f9f9;
    /* Светлый фон для комментариев */
}

.comment {
    text-align: start;
    /* flex-direction: column;
    display: flex;
    align-items: flex-start; */
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.comment-reply {
    display: flex;
    align-items: center;
    margin-top: 5px;
    padding: 5px 10px;
    border-radius: 15px;
    background-color: #f0f8ff;
    /* margin-left: 20px; */
    /* border-left: 2px solid #ddd; */
}

.comment-input,
.reply-input {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 15px;
    /* font-size: 1em; */
    cursor: pointer;
}

.comment-button,
.reply-button, .more-btn {
    padding: 4px 9px;
    color: #fff;
    background-color: hsl(211.06deg 100% 50%);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    /* transition: background-color 0.3s ease; */
}

.comment-button:hover,
.reply-button:hover,
.more-btn:hover {
    background-color: hsl(211.06deg 100% 57%);
}

.comment-button:active,
.reply-button:active,
.more-btn:active {
    background-color: hsl(211.06deg 100% 40%);
}

/* .comment-button:hover,
.reply-button:hover,
.more-btn:hover {
    background-color: #0056b3;
} */

.comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.user-name {
    font-weight: bold;
    color: #868889;
}

.user-current {
    color: #007BFF;
}

.user-other {
    color: #868889;
}

.timestamp {
    margin-left: auto;
    padding-right: 30px;
    font-size: 0.9em;
    color: #888;
}

.timestamp-left {
    padding-right: 10px;
}

.delete-button {
    /* position: absolute; */
    right: 10px;
    margin-right: 10px;
    /* padding: 8px; */
    border-radius: 5px;
    background-color: transparent;
    color: #888;
    border: none;
    cursor: pointer;
}

.delete-button:hover {
    color: #555;
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.pagination button {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 10px 15px;
    margin: 0 5px;
    cursor: pointer;
    color: black;
}

.pagination button.active {
    background-color: #007bff;
    color: white;
    border: 1px solid #007bff;
}

.pagination button:hover {
    background-color: #ddd;
    border: 1px solid #ccc;
    color: black;
}

.pagination button.active:hover {
    background-color: #007bff;
    border: 1px solid #007bff;
    color: #fff;
}

.news-creation{
    margin-bottom: 16px;
}


/* Большие экраны */
@media (min-width: 1025px) {}

/* Ноутбуки */
@media (max-width: 1024px) {}

/* Планшеты */
@media (max-width: 768px) {
    .news-title {
        font-size: 1.4em;
    }

    .timestamp {
        padding-right: 20px;
    }

    .news-content {
        font-size: 1.1em;
        padding: 0 20px;
    }
}

@media (max-width: 600px) {
    .news-title {
        font-size: 1.2em;
    }

    .timestamp {
        padding-right: 10px;
    }

    .news-content {
        font-size: 1em;
        padding: 0 16px;
    }
}

/* Мобильные устройства */
@media (max-width: 480px) {
    .news-title {
        font-size: 1.1em;
    }

    .timestamp {
        font-size: .7em;
        padding-right: 0px;
    }

    .news-content {
        font-size: 0.9em;
        padding: 0 10px;
    }

    .news-container {
        padding: 20px 0;
    }

    .news-wrapper{
        padding: 0 20px;
    }
    
}