.notification-management-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
}

/* .notification-management h2 {
  text-align: center;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
} */

.notification-management label {
  font-size: 1.1em;
  color: #555;
  display: block;
  margin-bottom: 8px;
}

.notification-management select {
  width: 100%;
  padding: 8px;
  font-size: 1em;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.notification-management textarea {
  width: 100%;
  height: 120px;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.notification-management button {
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 18px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.notification-management button:hover {
  background-color: #45a049;
}


/* Большие экраны */
@media (min-width: 1025px) {}

/* Ноутбуки */
@media (max-width: 1024px) {}

/* Планшеты */
@media (max-width: 768px) {
.notification-management label {
  font-size: 1em;
}

.notification-management select {
  font-size: 0.95em;
}

}

@media (max-width: 600px) {

.notification-management label {
  font-size: 0.95em;
}

.notification-management select {
  font-size: 0.9em;
}

}

/* Мобильные устройства */
@media (max-width: 480px) {
.notification-management label {
  font-size: 0.9em;
}

.notification-management select {
  font-size: 0.85em;
}

}