.modal {
  position: absolute;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin-top: 10px;
  margin-right: 10px;
  max-width: 500px;
  min-width: 260px;
}

/* @media (max-width: 768px) {
  .modal {
    width: 350px;
  }
} */

.modal-content {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-y: auto;
  text-align: left;
}

.modal-content p {
  margin: 0px;
}

.modal-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.notifications-header {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
}

.notification-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px 5px;
  font-size: 12px;
  font-weight: bold;
  min-width: 20px;
  text-align: center;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  margin: 0;
  position: absolute;
  top: -10px;
  right: -10px;
  opacity: .8;
}

.notification-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.notification-card:hover {
  background-color: #f1f1f1;
}

.unread-card {
  background-color: #f8fbeb;
}

.notification-list {
  max-height: 350px;
  overflow-y: auto;
  list-style: none;
  padding: 0px;
}

.notification-message {
  font-size: 14px;
  color: #555;
}

.notification-timestamp {
  font-size: 12px;
  color: #888;
  text-align: right;
  margin-top: 8px;
}

.notify-icon .bell {
  transition: transform .4s ease;
  transform-origin: center;
}

.notify-icon:hover .bell {
  transform: scale(1.2);
}

.username {
  color: #525c69;
  font-weight: 600;
}

/* Большие экраны */
@media (min-width: 1025px) {}

/* Ноутбуки */
@media (max-width: 1024px) {}

/* Планшеты */
@media (max-width: 768px) {

}

@media (max-width: 600px) {
  .modal {
    margin-left: 10px;
    right: 10px;
    margin-right: 0;
  }

}

/* Мобильные устройства */
@media (max-width: 480px) {
  .notify-icon {
    height: 40px;
    width: 40px;
  }
  .modal {
    min-width: 200px;
  }
}