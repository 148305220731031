.comment-section {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    outline: 1px solid #fff;
    text-align: start;
}

.like-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
}

.like {
    width: 24px;
    height: 24px;
}

.likes-count {
    color: #3f8aff;
}

.task-comments .action-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 5px;
    flex-wrap: wrap;
}

.task-comments .news-comment__button {
    background: none;
    color: gray;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.task-comments .news-comment__button:hover {
    color: #3c3c3c;
    text-decoration: underline;
}

.task-comments .delete-button:hover {
    color: #3c3c3c;
    text-decoration: underline;
}

.task-comments .delete-button {
    background: none;
    color: gray;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    position: unset;
}

.user-liked {
    color: #007bff !important;
    font-weight: 600;
}

.user-liked:hover {
    color: #0569d4 !important;
}

.comment {
    text-align: start;
    border-bottom: unset;
    padding: 10px 0;
}

.comment-main {
    display: inline-block;
    background-color: #f5f5f5;
    border-radius: 25px;
    padding: 10px 15px 10px 10px;
}

.comment-main__text {
    margin: 0;
}

.comment-main__text p {
    margin: 0;
}

.comment-wrapper {
    padding: 10px 0;
    text-align: start;
}

.comment-reply {
    display: flex;
    align-items: center;
    margin-top: 5px;
    padding: 5px 10px;
    border-radius: 15px;
    background-color: #f0f8ff;
}

.comments-section {
    background-color: unset;
    border: unset;
}

.comment-buttons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.comment-input {
    /* width: 97%; */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 18px;
    /* font-size: 14px; */
}

.comment-button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    align-self: flex-start;
}

.comment-button:hover {
    background-color: #0056b3;
}

.comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.user-name {
    margin-right: 10px;
    font-weight: bold;
    color: #525c69;
    cursor: pointer;
}

.user-current {
    color: #007BFF;
}

.user-other {
    color: #525c69;
}

/* .timestamp {
    margin-left: auto;
    padding-right: 30px;
    font-size: 0.9em;
    color: #888;
} */

.timestamp-left {
    padding-right: 10px;
    white-space: nowrap;
}

/* .input-container {
    transition: height 0.3s ease-in-out;
    height: 50px;
}

.input-container.expanded {
    height: 150px;
} */

.pinned-label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.pinned-input {
    width: auto;
}

.ql-container {
    border-radius: 0 0 18px 18px;
    border: 1px solid #ccc;
    font-size: 14px;
}

.ql-toolbar {
    border-radius: 18px 18px 0 0;
    border: 1px solid #ccc;
}

.task-comments {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
}

.hide-btn {
    align-self: start;
    background-color: transparent;
    color: #656565;
    font-size: 1em;
    transition: color .2s ease-in-out;
}

.hide-btn:hover {
    color: #919090;
}

.hide-btn:active {
    color: #474747;
}

.task-comments h3 {
    margin: 0px 0px 20px;
}

.new-comment-section {
    transition: height 0.3s ease-in-out;
}

.more-actions {
    display: flex;
}

/* Ноутбуки */
@media (max-width: 1024px) {
    .timestamp-left {
        font-size: 0.85em;
    }

    .pinned-label,
    .hide-btn {
        font-size: 0.95em;
    }
}

/* Планшеты */
@media (max-width: 768px) {
    .task-comments h3 {
        margin: 0px 0px 14px;
    }

    .user-name {
        font-size: 0.95em;
    }

    .timestamp-left,
    .timestamp {
        font-size: 0.8em;
    }

    .pinned-label,
    .hide-btn {
        font-size: 0.9em;
    }

    .comment-main__text p {
        font-size: 0.95em;
    }
}

@media (max-width: 600px) {
    .task-comments h3 {
        margin: 0px 0px 12px;
    }

    .user-name {
        font-size: 0.9em;
    }

    .timestamp-left,
    .timestamp {
        font-size: 0.75em;
    }

    .pinned-label,
    .hide-btn {
        font-size: 0.85em;
    }

    .news-item {
        margin-bottom: 10px;
    }

    .comment-main__text p {
        font-size: 0.9em;
    }
}

/* Мобильные устройства */
@media (max-width: 480px) {
    .task-comments h3 {
        margin: 0px 0px 10px;
    }

    .pinned-label,
    .hide-btn {
        font-size: 0.8em;
    }

    .user-name {
        font-size: 0.85em;
    }

    .timestamp-left,
    .timestamp {
        font-size: 0.75em;
    }

    .news-item {
        margin-bottom: 0;
    }

    .pagination {
        margin-top: 10px;
    }

    .comment-main__text p {
        font-size: 0.85em;
    }
}