.table-top-container {
  width: 100%;
  max-width: 100%;
  /* Таблица займет всю доступную ширину */
  overflow-x: auto;
  /* Добавляем горизонтальную прокрутку, если необходимо */
  margin: 0 auto;
  /* padding: 10px; */
}

.table-top-container table {
  /* width: auto; */
  /* Таблица будет подстраиваться под содержимое */
  /* min-width: 300px; */
  border-collapse: collapse;
  margin: 0 auto;
  max-width: 300px;
  width: 100%;
}

.table-top-container th, .table-top-container td {
  padding: 12px;
  /* Поддерживаем компактные отступы */
  text-align: center;
  border: 1px solid #ddd;
}

.table-top-container th {
  background-color: #4CAF50;
  color: white;
}

/* @media only screen and (max-width: 768px) {

  th,
  td {
    padding: 6px;
    /* Уменьшаем отступы на мобильных устройствах */
    /* font-size: 12px; */
    /* Уменьшаем размер шрифта на мобильных устройствах */
  /* }

  table {
    font-size: 12px;
  }
} */

.weekly-stats {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  margin-top: 20px;
}

.weekly-stats .table-container {
  width: 100%;
  max-width: 1200px;
  /* Ограничиваем максимальную ширину контейнера */
  overflow-x: auto;
  /* Добавляем горизонтальную прокрутку для таблицы */
  padding: 10px;
  margin: 0 auto;
  /* Центрирование контейнера */
}

.weekly-stats table {
  width: 100%;
  min-width: 600px;
  /* Минимальная ширина таблицы */
  border-collapse: collapse;
  margin: 0 auto;
  /* Центрируем таблицу */
}

.weekly-stats th,
.weekly-stats td {
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
}

.weekly-stats th {
  background-color: #4CAF50;
  color: white;
}

/* @media only screen and (max-width: 768px) {

  .weekly-stats th,
  td {
    padding: 8px;
    font-size: 12px;
    
  }

  .weekly-stats table {
    font-size: 12px;
  }
} */

/* Стили для итоговых строк */
.total-row {
  font-weight: bold;
  /* Жирный шрифт */
  background-color: #f0f0f0;
  /* Светлый фон */
  color: #000;
  /* Цвет текста */
}

/* th,
td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}

th {
  background-color: #4CAF50;
  color: white;
}

td {
  background-color: #f9f9f9;
}

tr:nth-child(even) td {
  background-color: #f2f2f2;
}

tr:hover td {
  background-color: #ddd;
  cursor: pointer;
} */

.error {
  color: red;
  font-weight: bold;
}

.clickable-row {
  cursor: pointer;
  transition: background-color 0.3s;
}

.clickable-row:hover {
  background-color: #f1f1f1;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.popup-inner h3 {
  margin-top: 0;
}

.popup-inner ul {
  list-style-type: none;
  padding: 0;
  max-height: 400px;
  overflow-y: auto;
}

.popup-inner ul li {
  margin: 5px 0;
}

.popup-inner .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: black;
}

.close-button:hover {
  color: red;
}

/* Большие экраны */
@media (min-width: 1025px) {

}

/* Ноутбуки */
@media (max-width: 1024px) {
  
}

/* Планшеты */
@media (max-width: 768px) {

}

@media (max-width: 600px){
  .table-top-container {
    font-size: 0.9em;
  }

  .weekly-stats table {
    font-size: 0.9em;
  }
  
}

/* Мобильные устройства */
@media (max-width: 480px) {

}