.modal-cr-project {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal-cr-project-content {
    background: white;
    padding: 20px;
    border-radius: 12px;
    width: 400px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.close-button {
    position: absolute;
    top: 4px;
    right: 10px;
    font-size: 24px;
    color: #777;
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.2s;
}

.close-button:hover {
    color: #333;
}

.modal-title {
    text-align: center;
    margin: 0;
    margin-bottom: 6px;
}

.modal-input {
    width: auto;
}

.modal-textarea {
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 10px;
    resize: none;
    height: 80px;
}

.modal-error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}