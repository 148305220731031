.dropdown {
    /* position: absolute; */
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    max-height: 200px;
    overflow-y: auto;
  }
  
  .filter-panel div {
    margin-bottom: 10px;
  }

  .input-check {
    width: auto;
    cursor: pointer;
  }

  .modal-filter {
    display: flex;

    position: absolute;
    background: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    z-index: 1000;
    max-height: 500px;
    overflow-y: auto;
    width: 100%;
    max-width: 360px;
}


  .modal-content-filter {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 20px;
    width: 100%;
  }
  
  .filter-container {
    /* margin: 20px; */
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  
  .filter-button{
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  .autocomplete-filter label {
    display: none;
  }

  .autocomplete-filter .chip p{
    margin: 0;
  }

  .autocomplete-filter .chips{
    margin: 6px 0;
  }

  .autocomplete-filter .autocomplete-input {
    margin-bottom: 0;
  }

  .checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-top: 12px;
}

.checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 6px;
}

.filters {
    display: flex;
    flex-direction: column;
}

.button-reset {
    color: #000;
    background: none
}

.filter-input {
    width: 300px;
}

/* Ноутбуки */
@media (max-width: 1024px) {

}

/* Планшеты */
@media (max-width: 768px) {
    .block-filter label, .checkbox-text {
        font-size: 0.95em;
    }
}

@media (max-width: 600px) {
    .filter-input {
        width: 240px;
    }
    
    .modal-filter {
        max-width: 300px;
    }

    .block-filter label, .checkbox-text {
        font-size: 0.9em;
    }

    .modal-content-filter {
        gap: 18px;
        max-height: 420px;
    }
}

/* Мобильные устройства */
@media (max-width: 480px) {
    .filter-input {
        width: 180px;
    }
    
    .modal-filter {
        max-width: 220px;
        max-height: 360px;
    }

    .block-filter label, .checkbox-text {
        font-size: 0.85em;
    }

    .modal-content-filter {
        gap: 16px;
    }

    .info-button .tooltip-text {
        display: none;
    }
}